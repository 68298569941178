import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/User';
import { StatusContext } from '../context/Status';

import { API, getLogo, getSystemName, isAdmin, isMobile, showError } from '../helpers';
import '../index.css';

import {
  IconCalendarClock,
  IconComment,
  IconDescend,
  IconGridView,
  IconCreditCard,
  IconGift,
  IconHistogram,
  IconHome,
  IconImage,
  IconKey,
  IconLayers,
  IconSetting,
  IconUser,
  IconBriefcase,
  IconPuzzle
} from '@douyinfe/semi-icons';
import { Layout, Nav } from '@douyinfe/semi-ui';

// 路由映射配置
const ROUTE_MAP = {
  home: '/',
  modelinfo: '/modelinfo',
  modelleaderboard: '/modelleaderboard',
  playground: '/playground',
  channel: '/channel',
  token: '/token',
  user: '/user',
  log: '/log',
  midjourney: '/midjourney',
  setting: '/setting',
  chat: '/chat',
  detail: '/detail'
};

// 导航菜单配置
const getNavigationConfig = (isAdminUser, localStorageSettings) => ({
  mainMenu: [
    {
      text: '首页',
      itemKey: 'home',
      icon: <IconHome />
    },
    {
      text: '模型',
      itemKey: 'modelinfo',
      icon: <IconGridView />
    },
    {
      text: '模型排行榜',
      itemKey: 'modelleaderboard',
      icon: <IconDescend />
    },
    {
      text: '渠道',
      itemKey: 'channel',
      icon: <IconLayers />,
      className: isAdminUser ? 'semi-navigation-item-normal' : 'tableHiddle'
    },
    {
      text: '聊天',
      itemKey: 'chat',
      icon: <IconComment />,
      className: localStorageSettings.chatLink ? 'semi-navigation-item-normal' : 'tableHiddle'
    },
    {
      text: '令牌',
      itemKey: 'token',
      icon: <IconKey />
    },
    {
      text: '游乐场',
      itemKey: 'playground',
      icon: <IconPuzzle />
    },
    {
      text: '绘图',
      itemKey: 'midjourney',
      icon: <IconImage />,
      className: localStorageSettings.enableDrawing ? 'semi-navigation-item-normal' : 'tableHiddle'
    }
  ],
  // 底部管理菜单
  bottomMenu: [
    {
      text: '用户管理',
      itemKey: 'user',
      icon: <IconUser />,
      className: isAdminUser ? 'semi-navigation-item-normal' : 'tableHiddle'
    },
    {
      text: '调用日志',
      itemKey: 'log',
      icon: <IconHistogram />
    },
    {
      text: '数据看板',
      itemKey: 'detail',
      icon: <IconCalendarClock />,
      className: localStorageSettings.enableDataExport ? 'semi-navigation-item-normal' : 'tableHiddle'
    },
    {
      text: '系统设置',
      itemKey: 'setting',
      icon: <IconSetting />
    }
  ]
});

const SiderBar = () => {
  const location = useLocation();
  const [, userDispatch] = useContext(UserContext);
  const [, statusDispatch] = useContext(StatusContext);
  const defaultIsCollapsed = isMobile() || localStorage.getItem('default_collapse_sidebar') === 'true';
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);
  const [selectedKeys, setSelectedKeys] = useState(['home']);
  
  const localStorageSettings = {
    chatLink: localStorage.getItem('chat_link'),
    enableDrawing: localStorage.getItem('enable_drawing') === 'true',
    enableDataExport: localStorage.getItem('enable_data_export') === 'true'
  };

  const { mainMenu, bottomMenu } = useMemo(
    () => getNavigationConfig(isAdmin(), localStorageSettings),
    [localStorageSettings]
  );

  const renderNavLink = ({ itemElement, props }) => (
    <Link style={{ textDecoration: 'none' }} to={ROUTE_MAP[props.itemKey]}>
      {itemElement}
    </Link>
  );

  const handleNavSelect = key => setSelectedKeys([key.itemKey]);

  const loadStatus = async () => {
    try {
      const { data: { success, data } } = await API.get('/api/status');
      if (success) {
        updateLocalStorage(data);
        statusDispatch({ type: 'set', payload: data });
      } else {
        showError('无法正常连接至服务器！');
      }
    } catch (error) {
      showError('服务器连接错误');
    }
  };

  useEffect(() => {
    loadStatus().then(() => {
      setIsCollapsed(isMobile() || localStorage.getItem('default_collapse_sidebar') === 'true');
    });
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const currentKey = Object.entries(ROUTE_MAP).find(([_, value]) => value === path)?.[0] || 'home';
    setSelectedKeys([currentKey]);
  }, [location.pathname]);

  return (
    <Layout>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Nav
          style={{ maxWidth: 200, height: '100%' }}
          defaultIsCollapsed={defaultIsCollapsed}
          isCollapsed={isCollapsed}
          onCollapseChange={setIsCollapsed}
          selectedKeys={selectedKeys}
          renderWrapper={renderNavLink}
          items={mainMenu}
          onSelect={handleNavSelect}
          header={{
            logo: <img src={getLogo()} alt="logo" style={{ marginRight: '0.75em' }} />,
            text: getSystemName()
          }}
        >
          {/* 底部管理菜单 */}
          <div style={{ 
            position: 'absolute',
            bottom: '60px',  // 为折叠按钮留出空间
            left: 0,
            right: 0,
            borderTop: '1px solid var(--semi-color-border)',
            paddingTop: '8px',
            background: 'var(--semi-color-bg-1)'  // 确保背景色与导航栏一致
          }}>
            <Nav
              style={{ maxWidth: 200 }}
              isCollapsed={isCollapsed}
              items={bottomMenu}
              onSelect={handleNavSelect}
              renderWrapper={renderNavLink}
            />
          </div>
          
          <Nav.Footer collapseButton={true} />
        </Nav>
      </div>
    </Layout>
  );
};

// 辅助函数：更新本地存储
const updateLocalStorage = (data) => {
  const keys = [
    'status', 'system_name', 'logo', 'footer_html', 'quota_per_unit',
    'display_in_currency', 'enable_drawing', 'enable_data_export',
    'data_export_default_time', 'default_collapse_sidebar', 'mj_notify_enabled'
  ];
  
  keys.forEach(key => {
    if (data[key] !== undefined) {
      localStorage.setItem(key, typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key]);
    }
  });

  ['chat_link', 'chat_link2'].forEach(key => {
    data[key] ? localStorage.setItem(key, data[key]) : localStorage.removeItem(key);
  });
};

export default SiderBar;
